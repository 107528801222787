import React from "react";
import { Layout } from "../layout";
import { PostContent } from "../content/post/postList";

export function PostPage() {
  return (
    <Layout>
      <PostContent />
    </Layout>
  );
}
